import { Controller } from "@hotwired/stimulus"
import { v4 as uuidv4 } from 'uuid';

URI = DEBUG ? 'http://localhost:3000/v1/places' : 'https://places.googleapis.com/v1/places'

export default class extends Controller {

  static targets = [
    'input',
    'searchBlock',
    'searchTemplate',
    'searchUlWrapperBlock',
    'searchUlBlock',
    'searchLiBlock',
    'searchUlTemplate',
    'searchLiTemplate',
    'selectedBlock',
    'selectedTemplate',
    'placeId',
    'placeName',
    'placeAddress'
  ]

  static values = {
    apiKey: String,
    delay: { 
      type: Number,
      default: 300
    },
    minLength: {
      type: Number,
      default: 2
    },
    place: {
      type: Object
    }
  }

  connect() {
    this.cache = {}
    this.sessionToken = uuidv4()
  }

  placeValueChanged() {
    if (this.placeSelected) {
      if (this.hasSearchBlockTarget) this.searchBlockTarget.remove()
      const content = this.selectedTemplateTarget.innerHTML
      const html = buildItem(content, this.placeValue)
      this.selectedTemplateTarget.insertAdjacentHTML('beforebegin', html)
      this.placeIdTarget.value = this.placeValue.id
      this.placeNameTarget.value = this.placeValue.name
      this.placeAddressTarget.value = this.placeValue.address
    } else {
      if (this.hasSelectedBlockTarget) this.selectedBlockTarget.remove()
      const content = this.searchTemplateTarget.innerHTML
      this.searchTemplateTarget.insertAdjacentHTML('beforebegin', content)
      this.inputTarget.focus()
    }
  }

  inputTargetConnected() {
    this.onInputChange = debounce(this.onInputChange.bind(this), this.delayValue)
    this.inputTarget.addEventListener('input', this.onInputChange)
  }

  inputTargetDisconnected() {
    if (this.hasInputTarget) {
      this.inputTarget.removeEventListener("input", this.onInputChange)
    }
  }

  disconnect() {}

  onInputChange() {
    if (this.terms && this.terms.length >= this.minLengthValue) {
      this.fetchResults()
    }
  }

  get terms() {
    return this.inputTarget.value.trim()
  }

  reset() {
    this.placeValue = {}
  }

  get placeSelected() {
    return Object.keys(this.placeValue).length > 0
  }

  fetchResults = async () => {
    try {
      const json = await this.fetchAutocomplete()
      const { suggestions } = json
      if (this.hasSearchUlWrapperBlockTarget) this.searchUlWrapperBlockTarget.remove()
      if (suggestions.length) {
        const wrapper = this.searchUlTemplateTarget.innerHTML
        this.searchBlockTarget.insertAdjacentHTML('beforeend', wrapper)
        for (let suggestion of suggestions) {
          const { placePrediction } = suggestion
          const place = {
            id: placePrediction.placeId,
            name: placePrediction.structuredFormat.mainText.text,
            address: placePrediction.structuredFormat.secondaryText.text
          }
          const content = this.searchLiTemplateTarget.innerHTML
          const html = buildItem(content, place)
          this.searchUlBlockTarget.insertAdjacentHTML('beforeend', html)
        }
      }

    } catch(error) {
      throw error
    }
  }

  fetchAutocomplete = async () => {
    const body = {
      input: this.terms,
      sessionToken: this.sessionToken,
      regionCode: 'ph'
    }
    const headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': this.apiKeyValue
    }
    if (this.cache[this.terms] !== undefined) return this.cache[this.terms]
    const response = await fetch([URI, 'autocomplete'].join(':'), {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    })
    const json = await response.json()
    this.cache[this.terms] = json
    return json

  }
/*
  async fetchDetails(placeId) {
    const headers = {
      'Content-Type': 'application/json',
      'X-Goog-Api-Key': this.apiKeyValue,
      'X-Goog-FieldMask': 'displayName,formattedAddress,rating,userRatingCount' //'places.id,places.displayName,places.formattedAddress,places.businessStatus,places.reviews'
    }

    let url = new URL([URI, placeId].join('/'))
    
    url.search = new URLSearchParams({
      sessionToken: this.sessionToken
    })
  
    const response = await fetch(url, {
      method: 'GET',
      headers: headers
    })
    return response.json()
  }
*/

  selectPlace(event) {
    const { place } = event.params
    this.placeValue = place
  }

}

const buildItem = (content, place) => {
  const { id, name, address } = place
  content = content.replace(/{id}/g, id)
  content = content.replace(/{name}/g, name)
  content = content.replace(/{address}/g, address)
  return content
}

const debounce = (fn, delay = 10) => {
  let timeoutId = null
  return (...args) => {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(fn, delay)
  }
}
